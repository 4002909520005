"use strict";

document.addEventListener("DOMContentLoaded", function(event) {
    init_basket();

    // кнопка Мини-корзина
    const miniCart2 = document.querySelector(".mini-cart2");
    if(miniCart2){
        miniCart2.querySelectorAll(".btn-rollup, .continue").forEach(elem => {
            elem.addEventListener("click", (e) => {
                e.preventDefault();
                miniCart2.classList.toggle('mini')
            });
        });
    }

});

function init_basket() {
    addToBasket();
    tovar_plus();
    delTovar();
}

function reInitBasket() {
    // $("#idBasket_reinit").load(window.location.href + " #idBasket", function () {
    //     init_basket();
    // });

    fetch(window.location.href + "#idBasket")
        .then(response => response.text())
        .then(html => {
            const wrapper = document.createElement('div');
            wrapper.innerHTML = html;
            const basket = wrapper.querySelector('#idBasket');
            document.querySelector('#idBasket_reinit').innerHTML = basket.innerHTML;
            init_basket();
        })
        .catch(error => console.error(error));
}

function addToBasket() {
    document.querySelectorAll('.button-to-cart').forEach(btn => {
        btn.addEventListener('click', (e) => {
            showLoader();

            const show_modal_cart = btn.dataset.show_modal_cart;
            const tovar_id = btn.dataset.tovar_id;
            let class_id = 0;
            let modif_id = 0;
            modif_id = btn.dataset.modif_id;

            if (btn.dataset.class_id) {
                class_id = btn.dataset.class_id;
            }

            let cnt = 1;
            if(document.querySelector(`#id_cnt_add_to_cart${tovar_id}`)){
                if (document.querySelector(`#id_cnt_add_to_cart${tovar_id}`).value) {
                    cnt = document.querySelector(`#id_cnt_add_to_cart${tovar_id}`).value;
                }
            }

            fetch("/ajax/basket/addToBasket.php", {
                method: "POST",
                body: JSON.stringify({
                    tovar_id: tovar_id,
                    cnt: cnt,
                    class_id: class_id,
                    modif_id : modif_id
                }),
                headers: {
                    "Content-Type": "application/json"
                }
            }).then(function(response) {
                return response.text();
            }).then(function(html) {
                change_tovar_cnt();
                changeTop();
                show_right_basket();

                if (getComputedStyle(document.querySelector('.header__mobile')).display === 'none' && show_modal_cart === "1") {
                    try {
                        new Fancybox(
                            [{
                                src : '/json/modal_cart/',
                                type : 'ajax'
                            }]
                        );
                    } catch (err) {
                        console.log(err);
                    }
                }

                /* Ecommerce - Добавление товара в корзину */
                window.dataLayer = window.dataLayer || [];

                let product_item,
                    product_id,
                    product_name,
                    product_price;
                // product_quantity;

                if (btn.closest('.catalog__item') || btn.closest('.catalog-list__item') || btn.closest('.catalog-list2__item')) {  // Если это список товаров, а не карточка, то переопределяем значения

                    // catalog
                    if (btn.closest('.catalog__item')) {
                        product_item = btn.closest('.catalog__item');
                        product_id = tovar_id;
                        product_name = product_item.querySelector('.catalog__title').textContent;
                        if (product_item.querySelector('.catalog__price')) {
                            product_price = parseInt(product_item.querySelector('.catalog__price').textContent.replace(/[^0-9]/gi, ''), 10);
                        } else {
                            product_price = 0;
                        }

                        // product_quantity = product_item.querySelector('.tovars-counter input').value;
                    }

                    // catalog-list
                    if (btn.closest('.catalog-list__item')) {
                        product_item = btn.closest('.catalog-list__item');
                        product_id = tovar_id;
                        product_name = product_item.querySelector('.catalog-list__title').textContent;
                        if (product_item.querySelector('.catalog-list__price')) {
                            product_price = parseInt(product_item.querySelector('.catalog-list__price').textContent.replace(/[^0-9]/gi, ''), 10);
                        } else {
                            product_price = 0;
                        }
                    }

                    // catalog-list2
                    if (btn.closest('.catalog-list2__item')) {
                        product_item = btn.closest('.catalog-list2__item');
                        product_id = tovar_id;
                        product_name = product_item.querySelector('.catalog-list2__title').textContent;
                        if (product_item.querySelector('.catalog-list2__price')) {
                            product_price = parseInt(product_item.querySelector('.catalog-list2__price').textContent.replace(/[^0-9]/gi, ''), 10);
                        } else {
                            product_price = 0;
                        }
                    }
                } else {
                    product_id = tovar_id;
                    product_name = document.querySelector('h1').textContent;
                    if(document.querySelector('.product__price')){
                        product_price = parseInt(document.querySelector('.product__price').textContent.replace(/[^0-9]/gi, ''), 10);
                    } else {
                        product_price = 0;
                    }
                }

                dataLayer.push({
                    "ecommerce": {
                        "currencyCode": "RUB",
                        "add": {
                            "products": [
                                {
                                    "id": product_id,
                                    "name": product_name,
                                    "price": product_price,
                                    // "brand": "",
                                    // "category": "",
                                    "quantity": cnt
                                }
                            ]
                        }
                    }
                });
                /* /Ecommerce - Добавление товара в корзину */

                hideLoader();
            });
        });
    });
}

function changeTop() {
    fetch("/ajax/basket/action.php", {
        method: "POST",
        body: JSON.stringify({
            flag: "changetop"
        }),
        headers: {
            "Content-Type": "application/json"
        }
    }).then(function(response) {
        return response.text();
    }).then(function(html) {
        document.querySelector('#basket_top').innerHTML = html;
        hideLoader();
    });
}

function tovar_plus() {
    document.querySelectorAll('.tovar_plus').forEach(btn => {
        btn.addEventListener('click', (e) => {
            showLoader();

            const tovar_id = btn.dataset.tovar_id;
            const m = btn.dataset.m;
            const formid = 'form_' + btn.dataset.form_id;
            let tt_now = document.querySelector(`#id_tovar_${tovar_id}`).value;
            let modif_id = 0;

            modif_id = btn.dataset.modif_id;

            if (m == "plus") {
                tt_now++;
            } else {
                tt_now--;
            }

            document.querySelector(`#id_tovar_${tovar_id}`).value = tt_now;

            fetch("/ajax/basket/action.php", {
                method: "POST",
                body: JSON.stringify({
                    flag: "plusminus",
                    tovar_id: tovar_id,
                    modif_id : modif_id,
                    m: m
                }),
                headers: {
                    "Content-Type": "application/json"
                }
            }).then(function(response) {
                return response.text();
            }).then(function(text) {
                change_tovar_cnt();
                changeTop();
                show_right_basket();
                reInitBasket();
                setTimeout(function () {
                    initSubmitForm(formid);
                    hideLoader();
                }, 1500);
            });
        });
    });

    document.querySelectorAll('.tovar_counter_list').forEach(btn => {
        btn.addEventListener('click', (e) => {
            const actm = btn.dataset.m;
            const tovar_id = btn.dataset.tovar_id;
            const cnt = parseInt(document.querySelector(`#id_cnt_add_to_cart${tovar_id}`).value);

            if (actm == "minus" && cnt > 1) {
                const new_cnt = cnt - 1;
                document.querySelector(`#id_cnt_add_to_cart${tovar_id}`).value = new_cnt;
            }

            if (actm == "plus") {
                const new_cnt = cnt + 1;
                document.querySelector(`#id_cnt_add_to_cart${tovar_id}`).value = new_cnt;
            }
        });
    });

    document.querySelectorAll('.tovar_change_cnt_manual').forEach(btn => {
        btn.addEventListener('keyup', (e) => {
            const tovar_id = btn.dataset.tovar_id;
            const formid = 'form_' + btn.dataset.form_id;
            const cnt = document.querySelector(`#id_tovar_${tovar_id}`).value;

            showLoader();

            fetch("/ajax/basket/action.php", {
                method: "POST",
                body: JSON.stringify({
                    flag: "change_cnt_tovar_manual",
                    tovar_id: tovar_id,
                    cnt: cnt
                }),
                headers: {
                    "Content-Type": "application/json"
                }
            }).then(function(response) {
                return response.text();
            }).then(function(text) {
                change_tovar_cnt();
                changeTop();
                show_right_basket();
                reInitBasket();
                setTimeout(function () {
                    initSubmitForm(formid);
                    hideLoader();
                }, 1500);
            });
        });
    });
}

function change_summ_top_basket() {
    fetch("/ajax/basket/action.php", {
        method: "POST",
        body: JSON.stringify({
            flag: "change_summ_top"
        }),
        headers: {
            "Content-Type": "application/json"
        }
    }).then(function(response) {
        return response.text();
    }).then(function(text) {
        document.querySelector('#id_div_summ_top_basket').innerHTML = text;
    });
}

function show_right_basket() {
    fetch("/ajax/basket/action.php", {
        method: "POST",
        body: JSON.stringify({
            flag: "show_ajax_right"
        }),
        headers: {
            "Content-Type": "application/json"
        }
    }).then(function(response) {
        return response.json();
    }).then(function(data) {
        if(document.querySelector('#right_cart_big')){
            document.querySelector('#right_cart_big').classList.add('active');
            document.querySelector('#right_cart_big_tovar_cnt').textContent = data.cnt;
            document.querySelector('#right_cart_big_tovar_sum').textContent = data.summ;
            document.querySelector('#right_cart_big').classList.remove('mini');

            document.querySelector("#right_cart_big_tovar_full").style.display = "block";
            document.querySelector("#right_cart_big_tovar_full2").style.display = "flex";
            document.querySelector("#right_cart_big_tovar_empty").style.display = "none";
        }

        if(document.querySelector('#right_card_mini')){
            document.querySelector('#right_card_mini').classList.add('active');
        }

        if(document.querySelector("#bottom_basket_line")){
            document.querySelector("#bottom_basket_line").style.display = "flex";
        }
    });
}

function change_tovar_cnt() {
    fetch("/ajax/basket/action.php", {
        method: "POST",
        body: JSON.stringify({
            flag: "change_tovar_cnt"
        }),
        headers: {
            "Content-Type": "application/json"
        }
    }).then(function(response) {
        return response.text();
    }).then(function(text) {
        document.querySelectorAll(".ajax_count_tovar").forEach(function(el) {
            el.textContent = text;

            // for mobile
            if (text > 0) {
                el.style.display = "block";
            } else {
                el.style.display = "none";
            }
        });
    });
}

function delTovar() {
    document.querySelectorAll(".del_tovar").forEach(btn => {
        btn.addEventListener("click", () => {
            const tovar_id = btn.dataset.tovar_id,
                  formid = 'form_' + btn.dataset.form_id,
                  product_id = btn.closest('tr').dataset.product_id;

            if (confirm("Вы действительно хотите удалить товар из корзины?")) {
                showLoader();

                fetch("/ajax/basket/action.php", {
                    method: "POST",
                    body: JSON.stringify({
                        flag: "del",
                        tovar_id: tovar_id
                    }),
                    headers: {
                        "Content-Type": "application/json"
                    }
                }).then(function(response) {
                    return response.text();
                }).then(function(text) {
                    changeTop();
                    reInitBasket();

                    /* Ecommerce - Удаление товара из корзины */
                    window.dataLayer = window.dataLayer || [];

                    const product_tr = document.querySelector(`tr[data-product_id='${product_id}']`),
                        product_name = product_tr.querySelector('.cart-name-td a').textContent,
                        product_quantity = product_tr.querySelector('.tovars-counter input').value;

                    dataLayer.push({
                        "ecommerce": {
                            "currencyCode": "RUB",
                            "remove": {
                                "products": [
                                    {
                                        "id": product_id,
                                        "name": product_name,
                                        // "category": "Аксессуары для мобильного телефона",
                                        "quantity": product_quantity
                                    }
                                ]
                            }
                        }
                    });
                    /* /Ecommerce - Удаление товара из корзины */

                    setTimeout(function () {
                        initSubmitForm(formid);
                        hideLoader();
                    }, 500);
                });
            }
        });
    });

    if(document.querySelector(".delete-all")){
        document.querySelector(".delete-all").addEventListener("click", () => {
            if (confirm("Вы действительно хотите удалить все товары из корзины?")) {
                fetch("/ajax/basket/action.php", {
                    method: "POST",
                    body: JSON.stringify({
                        flag: "del_all"
                    }),
                    headers: {
                        "Content-Type": "application/json"
                    }
                }).then(function(response) {
                    return response.text();
                }).then(function(text) {
                    changeTop();
                    reInitBasket();
                });
            }
        });
    }
}